import ReconnectingWebSocket from "reconnecting-websocket";
import { WEBSOCKET_URL } from "../config/constant.js";
export var waitingForPopupClosures = false;
export var newRows;
let setRowsDBState;
export var managerState;

export function updatemanager(newManager) {
  managerState = newManager;
  console.log("managerState updated");
  console.log(managerState);
}
export function updateSetRowsDB(newSetRowsDB) {
  setRowsDBState = newSetRowsDB;
}
export function updateRowsDB(rowsDB) {
  newRows = rowsDB;
  console.log("newRows updated");
  // console.log(newRows);
  // console.log(document.URL.includes("/tables"));
  // console.log(!waitingForPopupClosures);
  if (!waitingForPopupClosures && document.URL.includes("/tables")) setRowsDBState(newRows);
}

const editTable = (updatedRows, ticketWithFeedback, setRowsDB) => {
  // console.log("editTable()");
  // console.log(updatedRows);
  newRows = updatedRows.filter((obj) => obj.uuid != ticketWithFeedback.data.uuid);
  // console.log("Updated table rows:");
  // console.log(newRows);
  setRowsDB(newRows);
};
function formatNotificationText(popupContent, setRowsDB, addTicketToTable) {
  if (popupContent.header == "New worker feedback") {
    if (newRows) {
      //If window open in table
      editTable(newRows, popupContent, setRowsDB);
    }
    let purCanc =
      popupContent.data.worker_action_status == "C"
        ? "❌ Purchase Has Been Cancelled."
        : "✅ Purchase Completed Successfully.";
    return (
      <div>
        <p className="notification_title">{"🔔 " + popupContent.header}</p>
        <p className="notification_message">
          {popupContent.data.eventName} <br />
          {purCanc}
        </p>
      </div>
    );
  } else {
    if (newRows) {
      //If window open in table
      addTicketToTable(newRows, popupContent);
    }
    return (
      <div>
        <p className="notification_title">{"🔔 " + popupContent.header}</p>
        <p className="notification_message">
          {popupContent.data.event_name} <br />
          Price: {popupContent.data.ticket_price} <br />
          From: {popupContent.data.worker_email}
        </p>
      </div>
    );
  }
}

export class ws {
  constructor() {
    console.log("Starting websocket...");
    var endpoint = WEBSOCKET_URL;
    try {
      this.socket = new ReconnectingWebSocket(endpoint + "?webapp");
    } catch {
      console.log("Error in Reconnecting Websocket");
      this.socket = null;
    }
  }
  init(setallNotificationsUI, setRowsDB, listTicketsWithFeedback, setlistTicketsWithFeedback) {
    let addTicketToTable = (updatedRows, ticket) => {
      //console.log("addTicketToTable", ticket);
      const isDuplicate = updatedRows.filter((item) =>
        item.uuid === ticket.data.uuid ? ticket.data : null
      );
      // console.log(isDuplicate, "duplicate @@@");
      if (window.location.href.includes("/tables")) {
        if (isDuplicate.length > 0) {
          //if ticket exist add feedback received
          console.log("Adding feedback to ticket");
          newRows = updatedRows.map((item) =>
            item.uuid === ticket.data.uuid ? ticket.data : item
          );
          console.log(listTicketsWithFeedback, "listTicketsWithFeedback");
          let newTicketsWithFeedback = listTicketsWithFeedback.filter(
            (item) => item.uuid !== ticket.data.uuid
          );
          setlistTicketsWithFeedback(newTicketsWithFeedback);
        } else {
          // console.log("else part");
          ticket.data.tempManager = managerState;
          newRows = [...updatedRows, ticket.data];
        }
        setRowsDB(newRows);
      }
    };
    const displayNotifications = (backendMessage) => {
      const notificationFormatted = formatNotificationText(
        backendMessage,
        setRowsDB,
        addTicketToTable
      );
      notificationFormatted;
      //notify(notificationFormatted);

      let notificationList = localStorage.getItem("notifications");
      if (!notificationList || !Array.isArray(JSON.parse(notificationList))) {
        localStorage.setItem("notifications", JSON.stringify([backendMessage]));
        setallNotificationsUI([backendMessage]);
      } else {
        const jsonList = JSON.parse(notificationList);
        jsonList.push(backendMessage);
        localStorage.setItem("notifications", JSON.stringify(jsonList));
        setallNotificationsUI(jsonList);

        if (jsonList.length > 6) {
          localStorage.setItem("notifications", JSON.stringify(jsonList.slice(-4)));
        }
      }
    };

    this.socket.onopen = function (e) {
      console.log("Connection open", e);
      //socket.send(1);
    };
    this.socket.onmessage = function (e) {
      console.log("1 Message Received from backend", JSON.parse(e.data).message);
      //if new message received
      let jsonMessage = JSON.parse(e.data).message;
      displayNotifications(jsonMessage);
      //Display popup notification
      if (jsonMessage.header == "New ticket waiting for a decision") {
        navigator?.serviceWorker?.controller?.postMessage({
          head: jsonMessage.header,
          body: jsonMessage.data.event_name,
        });
      }
    };
  }
  close() {
    console.log("Connection closed");
    this.socket.close();
  }
}
