import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import TicketsApi from "api/tickets";
import PropTypes from "prop-types";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function CustomizedMenus({ setRowsDB, table }) {
  let [rangeSelected, setrangeSelected] = useState("24 Hrs");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    let rangeSelection = event.currentTarget.getAttribute("data-range");
    console.log(rangeSelection);
    if (rangeSelection !== null) {
      console.log("CLicked a new range");
      setrangeSelected(event.currentTarget.innerText);
      if (table == "expired") {
        TicketsApi.getExpiredTickets(rangeSelection).then(async (allTickets) => {
          console.log("API response: ");
          console.log(allTickets);
          setRowsDB(allTickets.data.status != "error" ? allTickets.data : []);
        });
      } else {
        TicketsApi.getConfirmedTickets(rangeSelection).then(async (allTickets) => {
          console.log("API response: ");
          console.log(allTickets);
          setRowsDB(allTickets.data.status != "error" ? allTickets.data : []);
        });
      }
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {rangeSelected}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} data-range="1" disableRipple>
          24 Hrs
        </MenuItem>
        <MenuItem onClick={handleClose} data-range="2" disableRipple>
          2 Days
        </MenuItem>
        <MenuItem onClick={handleClose} data-range="3" disableRipple>
          3 Days
        </MenuItem>
        <MenuItem onClick={handleClose} data-range="5" disableRipple>
          5 Days
        </MenuItem>
        <MenuItem onClick={handleClose} data-range="7" disableRipple>
          7 Days
        </MenuItem>
        <MenuItem onClick={handleClose} data-range="10" disableRipple>
          10 Days
        </MenuItem>
        <MenuItem onClick={handleClose} data-range="14" disableRipple>
          14 Days
        </MenuItem>
        <MenuItem onClick={handleClose} data-range="21" disableRipple>
          21 Days
        </MenuItem>
        <MenuItem onClick={handleClose} data-range="30" disableRipple>
          30 Days
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
// Typechecking props for the Table
CustomizedMenus.propTypes = {
  setRowsDB: PropTypes.func,
  table: PropTypes.string,
};
