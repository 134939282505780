// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[label="Purchase Feedback"] {
    width: -moz-fit-content!important;
    width: fit-content!important;
}`, "",{"version":3,"sources":["webpack://./src/components/MuiDialog/styles.css"],"names":[],"mappings":"AAAA;IACI,iCAA4B;IAA5B,4BAA4B;AAChC","sourcesContent":["[label=\"Purchase Feedback\"] {\n    width: fit-content!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
