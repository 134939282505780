import React from "react";
import momentTZ from "moment-timezone";
// prop-types is a library for typechecking of props

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import "./index.css";
import PropTypes from "prop-types";

// Add AG Grid
import { AgGridReact } from "ag-grid-react";
import SuiBadge from "components/SuiBadge";
import { getBadgeContent } from "examples/QueuedTable/Utils";
import { getBadgeColor } from "examples/QueuedTable/Utils";
import { formatDate } from "examples/QueuedTable/Utils";
import { numericComparator } from "examples/QueuedTable/Utils";
import { dateComparator } from "examples/QueuedTable/Utils";
import { currencyComparator } from "examples/QueuedTable/Utils";
import { useEffect, useRef, useState, useMemo } from "react";
// import { ContactPageSharp } from "@mui/icons-material";

// Cell Render
const FeedbackStatusCellRenderer = (params) => {
  const {
    column: { colId },
    data,
  } = params;
  return (
    <SuiBox key={data.uuid} p={1}>
      <SuiBadge
        variant="gradient"
        badgeContent={getBadgeContent(colId, data)}
        color={getBadgeColor(colId, data)}
        size="extra-small"
      />
    </SuiBox>
  );
};
const ReservedAtCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{formatDate(data.reserved_at)}</SuiBox>;
};
const ApprovedByCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.approved_by}</SuiBox>;
};
const EventNameCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.event_name}</SuiBox>;
};
const EventDateCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.event_date}</SuiBox>;
};
const EventTimeStapmCellRenderer = (params) => {
  const { data } = params;

  return <SuiBox>{eventTimeStapmComparator(data.ticket_action_timestamp)}</SuiBox>;
};
const TicketQuantityCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.ticket_quantity}</SuiBox>;
};
const TotalPriceCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.ticket_price}</SuiBox>;
};
const SeatRowCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.seat_row}</SuiBox>;
};
const SetaNumberCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.seat_numbers}</SuiBox>;
};
const SeatSectionCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.seat_section}</SuiBox>;
};
const WorkerEmailCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.worker_email}</SuiBox>;
};

// +====const moment = require('moment');============================
const ticketQuantityComparator = (number1, number2) => {
  if (parseInt(number1, 10) === parseInt(number2, 10)) {
    return 0;
  } else {
    return parseInt(number1, 10) > parseInt(number2, 10) ? 1 : -1;
  }
};

const eventTimeStapmComparator = (timestamp) => {
  if (!timestamp) return "-";
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = momentTZ.utc(timestamp).tz(timezone);
  // Get the weekday, day, month, and year components of the date
  const weekday = date.format("ddd"); // Abbreviated weekday name
  const day = date.format("D"); // Day of the month
  const month = date.format("MMMM"); // Month name
  const year = date.format("YYYY"); // Year

  // Get the hour and minute components of the time
  const hour = date.format("h"); // Hour in 12-hour format
  const minute = date.format("mm"); // Minute
  const ampm = date.format("A"); // AM/PM indicator

  // Construct the formatted date string
  const formattedDate = `${weekday} • ${month} ${day}, ${year} • ${hour}:${minute} ${ampm}`;

  return formattedDate; // Output: Tue • March 13, 2023 • 7:30 PM
};

const Table = ({ rowsDB }) => {
  const [columns, setColumns] = useState([]);
  // AG Grid Table
  const gridRef = useRef(null);

  const width = JSON.parse(localStorage.getItem("setTableData"));
  const getColumnWidth = (cName) => {
    const filteredObject = width.find((e) => e.colId === cName);
    const widthValue = filteredObject ? filteredObject.width : null;
    return widthValue;
  };
  const columnDefs = [
    {
      field: "approved_by",
      headerName: "Approved by",
      sortable: true,
      cellRenderer: ApprovedByCellRenderer,
      width: width ? getColumnWidth("approved_by") : 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "worker_action_status",
      headerName: "worker action status",
      sortable: true,
      cellRenderer: FeedbackStatusCellRenderer,
      width: width ? getColumnWidth("worker_action_status") : 200,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "reserved_at",
      headerName: "Reserved at",
      cellRenderer: ReservedAtCellRenderer,
      comparator: numericComparator,
      cellStyle: { textAlign: "left" },
      sortable: true,
      width: width ? getColumnWidth("reserved_at") : "",
    },
    {
      field: "event_name",
      headerName: "Event name",
      sortable: true,
      cellRenderer: EventNameCellRenderer,
      cellStyle: { textAlign: "left" },
      width: width ? getColumnWidth("event_name") : 300,
    },
    {
      field: "ticket_action_timestamp",
      headerName: "reserved time stamp",
      sortable: true,
      cellRenderer: EventTimeStapmCellRenderer,
      width: width ? getColumnWidth("ticket_action_timestamp") : 300,
      comparator: dateComparator,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "event_date",
      headerName: "event date",
      sortable: true,
      cellRenderer: EventDateCellRenderer,
      width: width ? getColumnWidth("event_date") : 300,
      comparator: dateComparator,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "ticket_quantity",
      headerName: "ticket quantity",
      sortable: true,
      cellRenderer: TicketQuantityCellRenderer,
      comparator: ticketQuantityComparator,
      width: width ? getColumnWidth("ticket_quantity") : 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "ticket_price",
      headerName: "ticket price",
      sortable: true,
      cellRenderer: TotalPriceCellRenderer,
      comparator: currencyComparator,
      width: width ? getColumnWidth("ticket_price") : 120,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "seat_row",
      headerName: "seat row",
      sortable: true,
      cellRenderer: SeatRowCellRenderer,
      comparator: numericComparator,
      width: width ? getColumnWidth("seat_row") : 150,
      tooltipField: "seat_row",
      cellStyle: { textAlign: "center" },
    },
    {
      field: "seat_numbers",
      headerName: "seat numbers",
      sortable: true,
      cellRenderer: SetaNumberCellRenderer,
      tooltipField: "seat_numbers",
      width: width ? getColumnWidth("seat_numbers") : "",
      cellStyle: { textAlign: "center" },
    },
    {
      field: "seat_section",
      headerName: "seat section",
      sortable: true,
      cellRenderer: SeatSectionCellRenderer,
      width: width ? getColumnWidth("seat_section") : 150,
      tooltipField: "seat section",
      cellStyle: { textAlign: "center" },
    },
    {
      field: "worker_email",
      headerName: "worker email",
      sortable: true,
      cellRenderer: WorkerEmailCellRenderer,
      tooltipField: "worker_email",
      cellStyle: { textAlign: "center" },
      width: width ? getColumnWidth("worker_email") : 250,
    },
    {
      field: "tag_name",
      headerName: "Tag",
      valueFormatter: (params) => params.data.tag_name,
      tooltipField: "tag_name",
      cellClass: "center-cell",
      cellStyle: { textAlign: "center" },
      width: width ? "132px" : "132px",
    },
    {
      field: "category",
      headerName: "Category",
      valueFormatter: (params) => params.data.category,
      tooltipField: "category",
      cellClass: "center-cell",
      cellStyle: { textAlign: "center" },
      width: width ? "132px" : "132px",
    },
    {
      field: "puller_name",
      headerName: "Puller Name",
      valueFormatter: (params) => params.data.puller_name,
      tooltipField: "puller_name",
      cellClass: "center-cell",
      cellStyle: { textAlign: "center" },
      width: width ? "132px" : "132px",
    },
    {
      field: "feedback_status",
      headerName: "Feedback status",
      cellRenderer: FeedbackStatusCellRenderer,
      sortable: true,
      width: width ? getColumnWidth("feedback_status") : 160,
      cellStyle: { textAlign: "center" },
    },
  ];
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
  }));
  // =================================================================
  const columnResize = (params) => {
    const columnWidths = params.columnApi.getColumnState().map((c) => ({
      colId: c.colId,
      width: c.width,
    }));
    localStorage.setItem("setTableData", JSON.stringify(columnWidths));
  };
  const setColumnIndex = JSON.parse(localStorage.getItem("setTableData"));

  useEffect(() => {
    if (setColumnIndex?.length > 0) {
      const newColumn = ["approved_by"];
      setColumnIndex?.map((j) => {
        return columnDefs.map((i) => {
          if ((i.field == "" ? "0" : i.field) == j.colId) {
            newColumn.push(i);
          }
        });
      });
      setColumns(columnDefs);
    } else {
      setColumns(columnDefs);
    }
  }, [rowsDB]);
  const gridOptions = {
    floatingFilter: true,
  };
  return (
    <SuiBox style={{ height: "inherit", width: "100%" }}>
      {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
      <SuiBox
        className="ag-theme-alpine"
        style={{
          width: "100%",
          maxHeight: "80vh",
          height: "calc(100vh - 270px)",
        }}
      >
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={columns}
          rowData={rowsDB}
          ref={gridRef}
          animateRows={true}
          getRowHeight={() => 65}
          gridOptions={gridOptions}
          suppressDragLeaveHidesColumns={true}
          onColumnResized={columnResize}
          onColumnMoved={columnResize}
          paginationPageSize={50}
          pagination={true}
        />
      </SuiBox>
    </SuiBox>
  );
};

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rowsDB: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rowsDB: PropTypes.arrayOf(PropTypes.object),
  setRowsDB: PropTypes.func,
};

export default React.memo(Table);
