import TicketsApi from "api/tickets";
import moment from "moment";
import { newRows, waitingForPopupClosures, updateRowsDB } from "api/websocket";
export const getBadgeContent = (fromDB, feedback) => {
  if (fromDB == "feedback_status") {
    if (feedback.feedback_status == "A") {
      return "Approved";
    } else if (feedback.feedback_status == "N") {
      return "Note";
    } else if (feedback.feedback_status == "D") {
      return "Denied";
    } else {
      return "Queued";
    }
  } else if (fromDB == "worker_action_status") {
    if (feedback.worker_action_status == "W") {
      return "Waiting";
    } else if (feedback.worker_action_status == "P") {
      return "Purchased";
    } else if (feedback.worker_action_status == "C") {
      return "Cancelled";
    } else {
      return "Queued";
    }
  }
};

export const getBadgeColor = (fromDB, feedback) => {
  if (fromDB == "feedback_status") {
    if (feedback.feedback_status == "A") {
      return "success";
    } else if (feedback.feedback_status == "N") {
      return "secondary";
    } else if (feedback.feedback_status == "D") {
      return "error";
    } else {
      return "primary";
    }
  } else if (fromDB == "worker_action_status") {
    if (feedback.worker_action_status == "P") {
      return "success";
    } else if (feedback.worker_action_status == "W") {
      return "secondary";
    } else if (feedback.worker_action_status == "C") {
      return "error";
    }
  } else if (fromDB == "Feedback received by CE") {
    if (feedback.feedback_received_by_CE === false) {
      return "feedbackReceivedByCEFalse";
    } else {
      return "feedbackReceivedByCETrue";
    }
  }
};

export const calculate_Timer_Expiration_Time = (reserved_at_date, milliseconds_to_expire) => {
  const result = new Date(parseInt(reserved_at_date) + parseInt(milliseconds_to_expire));
  return result;
};

export const handle_worker_feedback_submission = async (row, worker_feedback, setRowsDB) => {
  row.worker_action_status = worker_feedback;
  setRowsDB(row);
};

export const actionTicket = async (params) => {
  const { data, rowData, setRowData, action } = params;
  const newRowsFeedback = rowData.map((row) => {
    if (row.id === data.id) {
      // console.log({
      //   ...row,
      //   feedback_status: action,
      // });
      return {
        ...row,
        feedback_status: action,
      };
    }
    return row;
  });
  let feedback_json = {
    id: data.id,
    feedback: action,
    note: "",
  };
  await TicketsApi.updateFeedbackTicket(feedback_json);
  setRowData(newRowsFeedback);
};

export const isDateExpired = (date) => {
  // console.log("Date of timer expiration");
  // console.log(date);
  // console.log("isDateExpired()");
  const today = new Date();
  // console.log(date < today);
  return date < today;
};
export const formatDate = (date) => {
  return moment(new Date(parseInt(date))).fromNow();
};
export const isTicketStillReserved = (row) => {
  if (row.timer === 0) {
    if (isOlderThan10Minutes(row.reserved_at)) {
      return false;
    } else {
      return row.worker_action_status === "W";
    }
  }

  if (!row.timer || row.worker_action_status === "C") {
    return false;
  }

  const timerExpirationTime = calculate_Timer_Expiration_Time(row.reserved_at, row.timer);
  if (isDateExpired(timerExpirationTime)) {
    // console.log(row);
    row.worker_action_status = "C";
    row.feedback_status = "C";
    //call api to cancel ticket
    TicketsApi.timerTimeOut(row);
    //Remove ticket from State
    return false;
  }

  return true;
};

export function isOlderThan10Minutes(storedDateMilliseconds) {
  const minutesDifference = (new Date().getTime() - storedDateMilliseconds) / (1000 * 60);

  if (minutesDifference > 10) {
    return true;
  } else {
    return false;
  }
}

export const calculateTimerExpirationTime = (row) => {
  let timerExpirationTime;
  if (row.timer != 0) {
    timerExpirationTime = calculate_Timer_Expiration_Time(row.reserved_at, row.timer);
  } else {
    timerExpirationTime = "No Timer Detected";
  }
  return timerExpirationTime;
};

const dateStringFormats = [
  "ddd • MMM DD, YYYY • h:mm A",
  "ddd • MMMM DD, YYYY • h:mm A",
  "ddd MMM DD, YYYY h:mm A",
];

export const dateComparator = (date1, date2) => {
  if (!date1 || !date2) return;
  const newDate1 = moment(date1, dateStringFormats).format("YYYY-MM-DD HH:mm:ss");
  const newDate2 = moment(date2, dateStringFormats).format("YYYY-MM-DD HH:mm:ss");
  const timestamp1 = moment(newDate1, "YYYY-MM-DD HH:mm:ss").valueOf();
  const timestamp2 = moment(newDate2, "YYYY-MM-DD HH:mm:ss").valueOf();

  if (timestamp1 < timestamp2) {
    return -1;
  } else if (timestamp1 > timestamp2) {
    return 1;
  } else {
    return 0;
  }
};

export const currencyComparator = (valueA, valueB) => {
  const valueANumeric = parseFloat(valueA.replace(/[,$a-zA-Z]/g, ""));
  const valueBNumeric = parseFloat(valueB.replace(/[,$a-zA-Z]/g, ""));

  // Compare the numeric values
  if (valueANumeric === valueBNumeric) {
    return 0;
  } else {
    return valueANumeric > valueBNumeric ? 1 : -1;
  }
};

export const numericComparator = (number1, number2) => {
  if (parseInt(number1, 10) === parseInt(number2, 10)) {
    return 0;
  } else {
    return parseInt(number1, 10) > parseInt(number2, 10) ? -1 : 1;
  }
};

export const timerComparator = (valueA, valueB, data1, data2) => {
  const reservedAtA = data1.data.reserved_at;
  const reservedAtB = data2.data.reserved_at;

  const timerA = calculate_Timer_Expiration_Time(reservedAtA, valueA);
  const timerB = calculate_Timer_Expiration_Time(reservedAtB, valueB);
  return timerA - timerB;
};

export const handleFeedbackSubmission = async (
  ticket_info,
  rows,
  rowSetter,
  feedback_status,
  noteToSend = ""
) => {
  try {
    let note = noteToSend || ticket_info.note;
    let feedback = feedback_status;
    let feedback_json = {
      id: ticket_info.id,
      feedback: feedback,
      note: note,
    };
    let latestRows = newRows ? newRows : rows;
    await TicketsApi.updateFeedbackTicket(feedback_json);
    let newRowsFeedback = latestRows.map((row) => {
      if (row.uuid === ticket_info.uuid) {
        return {
          ...row,
          feedback_status: feedback_status,
          note: note,
          feedback_received_by_CE: false,
        };
      }
      return row;
    });
    if (!waitingForPopupClosures) {
      console.log("Waiting for popup closures is FALSE, setting roswDB");
      rowSetter(newRowsFeedback);
    } else {
      console.log("Waiting for popup closures is TRUE, updating state in WS");
      updateRowsDB(newRowsFeedback);
    }
  } catch (error) {
    console.log(error);
    alert("Something went wrong");
  }
};
export const handleWorkerFeedbackSubmission = async (
  ticket_info,
  worker_feedback_status,
  rows,
  rowSetter,
  noteToSend = ""
) => {
  try {
    console.log("handleWorkerFeedbackSubmission()");
    if (ticket_info.worker_action_status == "W") {
      ticket_info.note = noteToSend || ticket_info.note;
      ticket_info.approved_by = ticket_info.tempManager;
      ticket_info.worker_action_status = worker_feedback_status;
      let latestRows = newRows.length > 0 ? newRows : rows;
      console.log("Updating worker feedback status");
      console.log(ticket_info);
      TicketsApi.managerForceWorkerAction(ticket_info);
      // console.log("Before updating rows: ");
      // console.log(latestRows);
      let newRowsFeedback = latestRows.filter((row) => row.uuid !== ticket_info.uuid);
      // console.log("After updating rows Feedback ");
      // console.log(newRowsFeedback);
      updateRowsDB(newRowsFeedback);
    }
    console.log("Worker feedback already present on ticket... not changing state");
  } catch (error) {
    console.log("error while handling worker feedback submission");
    console.log(error);
  }
};
export const handleTimeOut = async (
  ticket_info,
  worker_feedback_status,
  rows,
  rowSetter,
  noteToSend = ""
) => {
  try {
    console.log("handleWorkerFeedbackSubmission()");
    if (ticket_info.worker_action_status == "W") {
      ticket_info.note = noteToSend || ticket_info.note;
      ticket_info.approved_by = ticket_info.tempManager;
      ticket_info.worker_action_status = worker_feedback_status;
      let latestRows = newRows.length > 0 ? newRows : rows;
      console.log("Updating worker feedback status");
      console.log(ticket_info);
      TicketsApi.timerTimeOut(ticket_info);
      // console.log("Before updating rows: ");
      // console.log(latestRows);
      let newRowsFeedback = latestRows.filter((row) => row.uuid !== ticket_info.uuid);
      // console.log("After updating rows Feedback ");
      // console.log(newRowsFeedback);
      updateRowsDB(newRowsFeedback);
    }
    console.log("Worker feedback already present on ticket... not changing state");
  } catch (error) {
    console.log("error while handling worker feedback submission");
    console.log(error);
  }
};

export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};
