import TicketsApi from "api/tickets";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// import StyledMenu from "components/MuiDropdown";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Table";
import React, { useState, useEffect } from "react";
// Custom styles for the Tables
import styles from "layouts/tables/styles";
// import SuiInput from "components/SuiInput/index";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import moment from "moment";
import { Spin } from "antd";

const Spinner = () => <Spin tip="Fetching Tickets..." size="large"></Spin>;

// Data
// import confirmedTicketsTableData from "layouts/tablesConfirmed/data/confirmedTicketsTableData";
//import { keys } from "@mui/system";
//import projectsTableData from "layouts/tables/data/projectsTableData";
function Tables_confirmed() {
  const classes = styles();
  let [rowsDB, setRowsDB] = useState([]);
  const [date, setDate] = useState(undefined);
  let [is_fetching_data, set_fetching_data] = useState(false);
  // const { columns } = confirmedTicketsTableData;
  //const { columns: prCols, rows: prRows } = projectsTableData;
  function formatDate(date) {
    let options = { timeZone: "America/Los_Angeles", month: "short", day: "numeric" };
    let month_day = new Date(date).toLocaleDateString("en-us", options);
    let time = new Date(date).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "America/Los_Angeles",
    });
    return month_day + " - " + time;
  }

  const download_CSV = () => {
    console.log("Inside Download CSV", rowsDB);
    const csv_headers =
      "Worker Action, Reserved at, Event name, Event date, Ticket quantity, Total price, Seat row, Seat numbers, Seat section, Worker email, Tag, Category, Puller name, Feedback status, Approved by";

    var csv_rows = "";
    let ordered_rows = [
      "worker_action_status",
      "reserved_at",
      "event_name",
      "event_date",
      "ticket_quantity",
      "ticket_price",
      "seat_row",
      "seat_numbers",
      "seat_section",
      "worker_email",
      "tag_name",
      "category",
      "puller_name",
      "feedback_status",
      "approved_by",
    ];
    let ordered_columns_array = [];
    rowsDB.forEach(function (row) {
      let ordered_row = [];
      for (let i = 0; i < ordered_rows.length; i++) {
        // console.log("🎈Next ordered_rows");
        // console.log(ordered_rows[i]);
        Object.keys(row).forEach((k) => {
          // console.log(k);
          if (k == ordered_rows[i]) {
            // console.log("😁 Matched");
            if (k == "reserved_at") ordered_row.push(formatDate(parseInt(row[k])));
            else if (k == "event_date") ordered_row.push(row[k].replaceAll(" • ", " - "));
            else if (k == "worker_action_status") ordered_row.push("Purchased");
            else {
              // console.log(row[k]);
              ordered_row.push(row[k]);
            }
          }
        });
      }
      ordered_columns_array.push(ordered_row);
    });
    // console.log(ordered_columns_array);
    for (let i = 0; i < ordered_columns_array.length; i++) {
      let stringed = ordered_columns_array[i].join("__").replace(/,/gi, " - ");
      // console.log("Stringed:");
      // console.log(stringed);
      let clean_string = stringed.replace(/__/gi, ",");
      csv_rows += clean_string;
      csv_rows += "\n";
    }

    let csv_Complete = csv_headers + "\n" + csv_rows;
    // console.log(csv_Complete);
    //let encodedUri = encodeURI(csv_Complete);

    let a = document.createElement("a");
    a.setAttribute("style", "display:none;");
    document.body.appendChild(a);
    let blob = new Blob([csv_Complete], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = "Ticket Relay - Confirmed Tickets.csv";
    a.click();
  };
  useEffect(async () => {
    set_fetching_data(true);
    const today = moment();
    // Get yesterday's date with the same time as today
    const yesterday = moment().subtract(1, "days").set({
      hour: today.hours(),
      minute: today.minutes(),
      second: today.seconds(),
    });
    // Format dates for API request
    const from = yesterday.format("YYYY-MM-DD");
    const to = today.format("YYYY-MM-DD");
    console.log("🎈from", from);
    console.log("🎈to", to);
    TicketsApi.getFilteredConfirmedTickets(from, to).then(async (allTickets) => {
      let sortTickets = () =>
        allTickets.data.sort(function (x, y) {
          return parseInt(y.reserved_at) - parseInt(x.reserved_at);
        });
      setRowsDB(allTickets.data.status != "error" ? sortTickets() : []);
      set_fetching_data(false);
    });
  }, []);

  const disabledDate = (date) => {
    const currentDate = new Date();
    return date > currentDate;
  };

  const callApi = async () => {
    if (date !== null && date !== undefined) {
      console.log("⭐callApi", date);
      set_fetching_data(true);
      const from = moment(date[0]).format("YYYY-MM-DD");
      const to = moment(date[1]).format("YYYY-MM-DD");
      TicketsApi.getFilteredConfirmedTickets(from, to).then(async (allTickets) => {
        setRowsDB(allTickets.data.status != "error" ? allTickets.data : []);
        set_fetching_data(false);
      });
    } else if (date !== undefined) {
      TicketsApi.getConfirmedTickets(1).then(async (allTickets) => {
        let sortTickets = () =>
          allTickets.data.sort(function (x, y) {
            return parseInt(y.reserved_at) - parseInt(x.reserved_at);
          });
        setRowsDB(allTickets.data.status != "error" ? sortTickets() : []);
        set_fetching_data(false);
      });
    }
  };
  useEffect(() => {
    callApi();
  }, [date]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Confirmed Tickets</SuiTypography>
              {is_fetching_data ? <Spinner /> : null}
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SuiBox pr={1}>
                  <DateRangePicker
                    value={date}
                    onChange={setDate}
                    size="md"
                    placeholder="Select Date Range"
                    style={{ width: 260, display: "block", marginRight: 20 }}
                    placement="bottomEnd"
                    disabledDate={disabledDate}
                  />
                </SuiBox>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  style={{ marginRight: "5px" }}
                  disableElevation
                  onClick={download_CSV}
                  endIcon={<DownloadIcon />}
                >
                  Download CSV
                </Button>
              </SuiBox>
            </SuiBox>
            <SuiBox customClass={classes.tables_table}>
              <Table rowsDB={rowsDB} />
            </SuiBox>
          </Card>
        </SuiBox>
        {/* <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SuiTypography variant="h6">Tickets with Feedback</SuiTypography>
          </SuiBox>
          <SuiBox customClass={classes.tables_table}>
            <Table columns={prCols} rows={prRows} />
          </SuiBox>
        </Card> */}
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables_confirmed;
