/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import PropTypes from "prop-types";
// import { newRows } from "../../api/websocket";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import Countdown from "react-countdown";
import SuiBox from "components/SuiBox";

import SuiBadge from "components/SuiBadge";
import FormDialog from "components/MuiDialog";
import "./index.css";

// UI Design
import DeleteIcon from "@mui/icons-material/Delete";
// import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  calculateTimerExpirationTime,
  currencyComparator,
  dateComparator,
  isOlderThan10Minutes,
  formatDate,
  getBadgeColor,
  getBadgeContent,
  handleWorkerFeedbackSubmission,
  handleTimeOut,
  numericComparator,
  timerComparator,
} from "./Utils";
import { Box, DialogContentText, FormControl, Paper, TextField } from "@mui/material";
import { Typography } from "antd";

const decodeHtmlEntities = (text) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = text;
  return txt.value;
};

// eslint-disable-next-line react/prop-types
const DecisionDialogContent = ({ ticket_info, dialogContentTitle }) => {
  return (
    <Box sx={{ p: 2 }}>
      <DialogContentText variant="h6" sx={{ mb: 2 }}>
        {dialogContentTitle}
      </DialogContentText>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          borderRadius: 2,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="body1" component="div" gutterBottom>
          <b>Event Name:</b> {decodeHtmlEntities(ticket_info.event_name)}
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
          <b>Event Date:</b> {ticket_info.event_date}
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
          <b>Total Price:</b> {ticket_info.ticket_price}
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
          <b>Ticket Quantity:</b> {ticket_info.ticket_quantity}
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
          <b>Seat Row:</b> {ticket_info.seat_row}
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
          <b>Seat Number:</b> {ticket_info.seat_numbers}
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
          <b>Seat Section:</b> {ticket_info.seat_section}
        </Typography>
      </Paper>
    </Box>
  );
};

const FeedbackReceivedByCE = (params) => {
  const {
    data,
    column: { colId },
  } = params;
  // console.log(data);
  return (
    <div className="statusConteiner" key={data.uuid}>
      <span className={getBadgeColor(colId, data)}></span>
    </div>
  );
};

const TimerCellRenderer = (params) => {
  const { node, setRowsDB, rowsDB } = params;
  const row = node.data;

  // Maintain previous timer value
  const [timerExpirationTime, setTimerExpirationTime] = useState("No Timer Detected");
  const [previousTimerValue, setPreviousTimerValue] = useState("No Timer Detected");

  useEffect(() => {
    const newExpirationTime = calculateTimerExpirationTime(row);
    if (newExpirationTime !== "No Timer Detected") {
      // Update timer value only if it's different from "No Timer Detected"
      setPreviousTimerValue(timerExpirationTime);
      setTimerExpirationTime(newExpirationTime);
    } else if (isOlderThan10Minutes(row.reserved_at)) {
      handleTimeOut(row, "C", rowsDB, setRowsDB);
    }
  }, []);

  const handleReservationExpired = async () => {
    console.log("❌❌❌ reservationExpired");
    handleTimeOut(row, "C", rowsDB, setRowsDB);
  };

  return (
    <SuiBox key={row.uuid} p={1}>
      {/* Display previous timer value if the new timer value is "No Timer Detected" */}
      {timerExpirationTime === "No Timer Detected" && previousTimerValue !== "No Timer Detected" ? (
        <Countdown
          date={previousTimerValue}
          daysInHours={true}
          onComplete={() => handleReservationExpired(row.uuid)}
        >
          <SuiBadge
            variant="gradient"
            badgeContent="Reservation Expired"
            color="error"
            size="extra-small"
            aria-description="badge"
          />
        </Countdown>
      ) : (
        /* Display the new timer value */
        <Countdown
          date={timerExpirationTime}
          daysInHours={true}
          onComplete={() => handleReservationExpired(row.uuid)}
        >
          <SuiBadge
            variant="gradient"
            badgeContent="Reservation Expired"
            color="error"
            size="extra-small"
            aria-description="badge"
          />
        </Countdown>
      )}
    </SuiBox>
  );
};

const CancelCellRenderer = (params) => {
  const { data, rowsDB, setRowsDB } = params;
  const row = data;

  const handleSubmit = () => {
    handleWorkerFeedbackSubmission(row, "C", rowsDB, setRowsDB);
  };
  return (
    <FormDialog
      setRowsDB={setRowsDB}
      rows={rowsDB}
      btn_text={{ content: <DeleteIcon /> }}
      ticket_info={data}
      submitFunction={handleSubmit}
      cancelBtnText="Cancel"
      submitBtnText="Delete Ticket"
      dialogtitle="Delete"
    >
      <DecisionDialogContent ticket_info={data} dialogContentTitle={"Delete Ticket"} />
    </FormDialog>
  );
};

const PurchaseCallRenderer = (params) => {
  const { data, rowsDB, setRowsDB } = params;
  const row = data;

  const handleSubmit = () => {
    handleWorkerFeedbackSubmission(row, "P", rowsDB, setRowsDB);
  };
  return (
    <FormDialog
      setRowsDB={setRowsDB}
      rows={rowsDB}
      btn_text={{ content: <ShoppingCartIcon /> }}
      ticket_info={data}
      submitFunction={handleSubmit}
      cancelBtnText="Cancel"
      submitBtnText="Purchese Ticket"
      dialogtitle="Purchase"
    >
      <DecisionDialogContent ticket_info={data} dialogContentTitle={"Purchase Ticket"} />
    </FormDialog>
  );
  // const { data, rowsDB, setRowsDB } = params;
  // const row = data;
  // return (
  //   <IconButton color="success" onClick={() => handlePurchaseTicket(row, rowsDB, setRowsDB)}>
  //     <ShoppingCartIcon />
  //   </IconButton>
  // );
};
const FeedbackStatusCellRenderer = (params) => {
  const {
    column: { colId },
    data,
  } = params;
  return (
    <SuiBox key={data.uuid} p={1}>
      <SuiBadge
        variant="gradient"
        badgeContent={getBadgeContent(colId, data)}
        color={getBadgeColor(colId, data)}
        size="extra-small"
      />
    </SuiBox>
  );
};

const NoteCellRenderer = (params) => {
  const { data, rowsDB, setRowsDB, handleFeedbackSubmission } = params;
  const noteTextRef = useRef();
  const handleInputChange = (event) => {
    noteTextRef.current = event.target.value;
  };
  const handleSubmit = async () => {
    await handleFeedbackSubmission(data, rowsDB, setRowsDB, "N", noteTextRef.current);
  };
  return (
    <SuiBox key={data.uuid} p={1}>
      <FormDialog
        setRowsDB={setRowsDB}
        rows={rowsDB}
        btn_text={{ content: "Note" }}
        ticket_info={data}
        submitFunction={handleSubmit}
        cancelBtnText="Cancel"
        submitBtnText="Send Note"
        dialogtitle={""}
        submitBtnHeight={"41px"}
        submitBtnWidth={"120px"}
        rowNode={params}
      >
        <DecisionDialogContent
          ticket_info={data}
          dialogContentTitle={"Send a Note to the Worker for these tickets."}
        />
        <FormControl fullWidth>
          <TextField
            autoFocus
            margin="dense"
            id="outlined-basic"
            label="Note"
            fullWidth
            placeholder={data.note}
            multiline
            variant="outlined"
            onChange={handleInputChange}
          />
        </FormControl>
      </FormDialog>
    </SuiBox>
  );
};
NoteCellRenderer.displayName = "NoteCellRenderer";

const ApprovCellRenderer = (params) => {
  const { data, rowsDB, setRowsDB, handleFeedbackSubmission } = params;

  const handleSubmit = async () => {
    // Get first visible row
    // const firstVisibleRow = gridRef.getFirstDisplayedRow();
    // console.log(firstVisibleRow);
    await handleFeedbackSubmission(data, rowsDB, setRowsDB, "A");
  };
  return (
    <SuiBox key={data.uuid} p={1}>
      <FormDialog
        setRowsDB={setRowsDB}
        rows={rowsDB}
        btn_text={{ content: "Approve" }}
        ticket_info={data}
        submitFunction={handleSubmit}
        cancelBtnText="Cancel"
        submitBtnText="Approve Ticket"
        dialogtitle={""}
        submitBtnHeight={"42px"}
        submitBtnWidth={"151px"}
      >
        <DecisionDialogContent ticket_info={data} dialogContentTitle={"Approve Ticket"} />
      </FormDialog>
    </SuiBox>
  );
};

const DenyCellRenderer = (params) => {
  const { data, rowsDB, setRowsDB, handleFeedbackSubmission } = params;
  const handleSubmit = async () => {
    await handleFeedbackSubmission(data, rowsDB, setRowsDB, "D");
  };

  return (
    <SuiBox key={data.uuid} p={1}>
      <FormDialog
        setRowsDB={setRowsDB}
        rows={rowsDB}
        btn_text={{ content: "Deny" }}
        ticket_info={data}
        submitFunction={handleSubmit}
        cancelBtnText="Cancel"
        submitBtnText="Deny Ticket"
        dialogtitle={""}
        submitBtnHeight={"41px"}
        submitBtnWidth={"130px"}
        rowNode={params}
      >
        <DecisionDialogContent ticket_info={data} dialogContentTitle={"Deny Ticket"} />
      </FormDialog>
    </SuiBox>
  );
};
const DeliveryMethodCellRenderer = ({ data }) => {
  const imageContent = data.delivery_method;

  return imageContent?.toLowerCase().includes("mobile") ? (
    <img src="mobile-approved.jpg" width="50px" height="50px" alt={data.delivery_method} />
  ) : (
    <img src="mobile-den.png" width="50px" height="50px" alt={data.delivery_method} />
  );
};

const Table = ({
  rowsDB,
  // setRowsDB,
  setRowsDB_instant,

  rowsWaitingToUpdate,
  handleCheckBoxCheck,
  shouldClearSelection,
  handleFeedbackSubmission,
}) => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [columns, setColumns] = useState([]);

  const onSelectionChanged = useCallback(() => {
    var selectedRows = gridRef.current.api.getSelectedRows();
    console.log("OnSelected Rows");
    console.log(selectedRows);
    const newSelection = [];
    selectedRows.forEach((item) => {
      newSelection.push(item);
    });
    handleCheckBoxCheck(newSelection);
  }, []);

  useEffect(() => {
    const handleMouseEnter = (event) => {
      event.stopPropagation();
    };

    const handleMouseLeave = (event) => {
      event.stopPropagation();
    };

    const intervalId = setInterval(() => {
      // Select the element
      const element = document.querySelector(".ag-center-cols-container");

      // If the element exists
      if (element) {
        // Add event listeners for 'mouseenter' and 'mouseleave'
        element.addEventListener("mouseenter", handleMouseEnter);
        element.addEventListener("mouseleave", handleMouseLeave);

        // Clear the interval
        clearInterval(intervalId);
      }
    }, 1000); // Check every second

    // Cleanup function
    return () => {
      // Remove event listeners when component unmounts
      const element = document.querySelector(".ag-body.ag-layout-normal");
      if (element) {
        element.removeEventListener("mouseenter", handleMouseEnter);
        element.removeEventListener("mouseleave", handleMouseLeave);
      }

      // Clear interval
      clearInterval(intervalId);
    };
  }, []);

  const width = JSON.parse(localStorage.getItem("columnWidths"));
  const getColumnWidth = (cName) => {
    const filteredObject = width.find((e) => e.colId === cName);
    const widthValue = filteredObject ? filteredObject.width : null;
    return widthValue;
  };
  const columnDefs = useMemo(() => [
    {
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      sortable: false,
      width: width ? getColumnWidth("0") : 50,
    },
    {
      field: "Feedback received by CE",
      headerName: "Feedback received by CE",
      sortable: false,
      cellRenderer: FeedbackReceivedByCE,
      cellStyle: { allignment: "center" },
      width: width ? getColumnWidth("feedback received by CE") : 200,
    },
    {
      field: "timer",
      headerName: "Timer",
      cellRenderer: TimerCellRenderer,
      cellRendererParams: {
        rowsDB: rowsDB,
        setRowsDB: setRowsDB_instant,
      },
      comparator: timerComparator,
      width: width ? getColumnWidth("timer") : 180,
      cellStyle: { textAlign: "left" },
    },
    {
      field: "cancel",
      headerName: "Cancel",
      sortable: false,
      cellRenderer: CancelCellRenderer,
      cellRendererParams: {
        rowsDB: rowsWaitingToUpdate,
        setRowsDB: setRowsDB_instant,
      },
      width: width ? getColumnWidth("cancel") : 100,
      cellStyle: { textAlign: "left" },
    },
    {
      field: "purchase",
      headerName: "Purchase",
      sortable: false,
      cellRenderer: PurchaseCallRenderer,
      cellRendererParams: {
        rowsDB: rowsWaitingToUpdate,
        setRowsDB: setRowsDB_instant,
      },
      width: width ? getColumnWidth("purchase") : 100,
      cellStyle: { textAlign: "left" },
    },
    {
      field: "delivery_method",
      headerName: "delivery method",
      cellRenderer: DeliveryMethodCellRenderer,
      cellRendererParams: {
        rowsDB: rowsWaitingToUpdate,
      },
      sortable: false,
      cellStyle: { textAlign: "center" },
      width: width ? getColumnWidth("center") : 150,
    },
    {
      field: "reserved_at",
      headerName: "Reserved at",
      valueFormatter: (params) => formatDate(params.data.reserved_at),
      comparator: numericComparator,
      cellStyle: { textAlign: "left" },
      width: width ? getColumnWidth("reserved_at") : 150,
    },
    {
      field: "reseller_website",
      headerName: "Reseller Website",
      valueFormatter: (params) => params.data.reseller_website,
      cellStyle: { textAlign: "left" },
      width: width ? getColumnWidth("reseller_website") : 150,
    },
    {
      field: "event_name",
      headerName: "Event name",
      valueFormatter: (params) => params.data.event_name,
      cellStyle: { textAlign: "left" },
      width: width ? getColumnWidth("event_name") : 300,
    },
    {
      field: "venue",
      headerName: "Venue",
      valueFormatter: (params) => params.data.venue,
      tooltipField: "venue",
      width: width ? getColumnWidth("venue") : 300,
      cellStyle: { textAlign: "left" },
    },
    {
      field: "event_date",
      headerName: "Event date",
      valueFormatter: (params) => params.data.event_date,
      width: width ? getColumnWidth("event_date") : 300,
      comparator: dateComparator,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "ticket_quantity",
      headerName: "Ticket quantity",
      valueFormatter: (params) => params.data.ticket_quantity,
      comparator: numericComparator,
      width: width ? getColumnWidth("ticket_quantity") : 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "ticket_price",
      headerName: "Total price",
      valueFormatter: (params) => params.data.ticket_price,
      comparator: currencyComparator,
      width: width ? getColumnWidth("ticket_price") : 120,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "single_ticket_prices",
      headerName: "Single Ticket Price",
      valueFormatter: (params) => params.data.single_ticket_prices,
      comparator: currencyComparator,
      width: width ? getColumnWidth("single_ticket_prices") : 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "seat_description",
      headerName: "Seat description",
      valueFormatter: (params) => params.data.seat_description,
      width: width ? getColumnWidth("seat_description") : 150,
      tooltipField: "seat section",
      cellStyle: { textAlign: "center" },
    },
    {
      field: "seat_section",
      headerName: "Seat section",
      valueFormatter: (params) => params.data.seat_section,
      width: width ? getColumnWidth("seat_section") : 150,
      tooltipField: "seat section",
      cellStyle: { textAlign: "center" },
    },
    {
      field: "seat_row",
      headerName: "Seat row",
      valueFormatter: (params) => params.data.seat_row,
      comparator: numericComparator,
      width: width ? getColumnWidth("seat_row") : 150,
      tooltipField: "seat_row",
      cellStyle: { textAlign: "center" },
    },
    {
      field: "seat_numbers",
      headerName: "Seat numbers",
      valueFormatter: (params) => params.data.seat_numbers,
      tooltipField: "seat_numbers",
      width: width ? getColumnWidth("seat_numbers") : 120,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "worker_email",
      headerName: "Worker email",
      valueFormatter: (params) => params.data.worker_email,
      tooltipField: "worker_email",
      cellClass: "center-cell",
      cellStyle: { textAlign: "center" },
      width: width ? getColumnWidth("worker_email") : 150,
    },
    {
      field: "tag_name",
      headerName: "Tag",
      valueFormatter: (params) => params.data.tag_name,
      tooltipField: "tag_name",
      cellClass: "center-cell",
      cellStyle: { textAlign: "center" },
      width: width ? getColumnWidth("tag_name") : 132,
    },
    {
      field: "feedback_status",
      headerName: "Feedback status",
      cellRenderer: FeedbackStatusCellRenderer,
      sortable: true,
      width: width ? getColumnWidth("feedback_status") : 160,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "approv",
      headerName: "Approve",
      sortable: false,
      cellRenderer: ApprovCellRenderer,
      cellRendererParams: {
        rowsDB: rowsWaitingToUpdate,
        gridRef: gridRef,
        setRowsDB: setRowsDB_instant,
        handleFeedbackSubmission: handleFeedbackSubmission,
      },
      width: width ? getColumnWidth("approv") : 150,
    },
    {
      field: "deny",
      headerName: "Deny",
      sortable: false,
      cellRenderer: DenyCellRenderer,
      cellRendererParams: {
        rowsDB: rowsWaitingToUpdate,
        handleFeedbackSubmission: handleFeedbackSubmission,
        setRowsDB: setRowsDB_instant,
      },
      width: width ? getColumnWidth("deny") : 120,
    },
    {
      field: "note",
      headerName: "Note",
      cellRenderer: NoteCellRenderer,
      cellRendererParams: {
        rowsDB: rowsWaitingToUpdate,
        setRowsDB: setRowsDB_instant,
        handleFeedbackSubmission: handleFeedbackSubmission,
      },
      sortable: false,
      cellStyle: { textAlign: "center" },
      width: width ? getColumnWidth("center") : 120,
    },
  ]);

  useEffect(() => {
    console.log("⬅️Table is being rendered");
    let setColumnIndex = JSON.parse(localStorage.getItem("columnWidths"));
    if (!setColumnIndex) {
      setColumnIndex = columnDefs.map((column) => ({
        colId: column.field,
        width: column.width,
      }));
      localStorage.setItem("columnWidths", JSON.stringify(setColumnIndex));
    }

    const newColumns = columnDefs.filter((i) =>
      setColumnIndex?.some((j) => (i.field || "0") == j.colId)
    );
    if (columns !== newColumns) {
      setColumns(newColumns);
    }
    //add check for timer expired
  }, [rowsDB]);

  useEffect(() => {
    handleCheckBoxCheck([]);
  }, [shouldClearSelection]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    enableCellChangeFlash: true,
    suppressMaintainUnsortedOrder: true,
  }));

  const columnResize = (params) => {
    console.log("Columns are being resized");
    const columnWidths = params.columnApi.getColumnState().map((c) => ({
      colId: c.colId,
      width: c.width,
    }));
    console.log("Params");
    console.log(params.columnApi.getColumnState());
    localStorage.setItem("columnWidths", JSON.stringify(columnWidths));
  };
  const gridOptions = {
    suppressColumnVirtualisation: true,
    deltaRowDataMode: true,
  };
  const getRowId = useCallback(function (params) {
    return params.data.id;
  }, []);
  return (
    <SuiBox style={{ height: "inherit" }}>
      {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
      <SuiBox
        className="ag-theme-alpine"
        style={{
          width: "100%",
          maxHeight: "80vh",
          height: "calc(100vh - 270px)",
        }}
      >
        <AgGridReact
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowsDB} // Row Data for Rows
          columnDefs={columns} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="multiple" // Options - allows click selection of rows
          onSelectionChanged={onSelectionChanged}
          getRowHeight={() => 65}
          getRowId={getRowId}
          gridOptions={gridOptions}
          suppressDragLeaveHidesColumns={true}
          onColumnResized={columnResize}
          onColumnMoved={columnResize}
        />
      </SuiBox>
    </SuiBox>
  );
};

Table.propTypes = {
  rowsDB: PropTypes.arrayOf(PropTypes.object),
  rowsWaitingToUpdate: PropTypes.arrayOf(PropTypes.object),
  setRowsDB: PropTypes.func,
  handleFeedbackSubmission: PropTypes.func,
  setRowsDB_instant: PropTypes.func,

  handleCheckBoxCheck: PropTypes.func,
  shouldClearSelection: PropTypes.bool,
  ticket_info: PropTypes.object,
  selectedTickets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  dialogContentTitle: PropTypes.string,
};

export default React.memo(Table);
