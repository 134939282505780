import TicketsApi from "api/tickets";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/QueuedTable";
import React, { useState, useEffect, useRef, useCallback } from "react";
import "./style.css";
// Custom styles for the Tables
import styles from "layouts/tables/styles";
import { isTicketStillReserved } from "../../examples/QueuedTable/Utils";
import { useAuth } from "auth-context/auth.context";

// Data
import queuedTicketsTableData from "layouts/tables/data/queuedTicketsTableData";
// import ReactVirtualizedTable from "examples/QueuedTable";
import Table from "examples/QueuedTable/index";
// import { handleFeedbackSubmission } from "examples/QueuedTable/Utils";
import { newRows, updateRowsDB, updateSetRowsDB, updatemanager } from "api/websocket";
//import projectsTableData from "layouts/tables/data/projectsTableData";
import { Spin } from "antd";
const Spinner = () => <Spin tip="Fetching Tickets..." size="large"></Spin>;

function Tables() {
  const classes = styles();
  let [rowsDB, setRowsDB] = useState([]);
  updateSetRowsDB(setRowsDB);
  let [is_fetching_data, set_fetching_data] = useState(false);
  const { columns } = queuedTicketsTableData;
  const selectedTickets = useRef([]);
  const [resendingFeedback, setresendingFeedback] = useState(false);
  const rowsDBRef = useRef(rowsDB);
  let [listTicketsWithFeedback, setlistTicketsWithFeedback] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const currentFeedBackForBulkSelection = useRef([]);
  const [shouldClearSelection, setShouldClearSelection] = useState(false);
  let { user } = useAuth();
  useEffect(() => {
    console.log("🆗 USER:");
    console.log(user);
    updatemanager(user.email);
    set_fetching_data(true);
    TicketsApi.getQueuedTickets()
      .then((allTickets) => {
        let rows = [];
        if (allTickets.data.status !== "error") {
          rows = allTickets.data.map((row) => ({ ...row, open: false, tempManager: user.email }));
        }
        setRowsDB(rows);
        set_fetching_data(false);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleCheckBoxCheck = useCallback((selectedRows) => {
    console.log("🆗 handleCheckBoxCheck");
    if (selectedRows.length > 0) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
    selectedTickets.current = selectedRows;
  }, []);

  const handleFeedbackSubmission = async (
    ticket_info,
    rows,
    rowSetter,
    feedback_status,
    noteToSend = ""
  ) => {
    try {
      console.log("⬅️⬅️⬅️⬅️handleFeedbackSubmission");
      let note = noteToSend || ticket_info.note;
      let feedback = feedback_status;
      let feedback_json = {
        id: ticket_info.id,
        feedback: feedback,
        note: note,
        approved_by: user.email,
      };
      let latestRows = newRows ? newRows : rows;
      let newRowsFeedback = latestRows.map((row) => {
        if (row.uuid === ticket_info.uuid) {
          return {
            ...row,
            feedback_status: feedback_status,
            note: note,
            approved_by: user.email,
            feedback_received_by_CE: false,
          };
        }
        return row;
      });
      console.log("Waiting for popup closures is FALSE, setting roswDB");
      rowSetter(newRowsFeedback);
      //Add ticket with feedback to list, in ordre to check if chrome extension receive feedback
      setlistTicketsWithFeedback([...listTicketsWithFeedback, ticket_info]);
      console.log(listTicketsWithFeedback);
      return await TicketsApi.updateFeedbackTicket(feedback_json);
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  };
  const handleBulkFeedbackSubmission = async (
    ticket_info,
    rows,
    rowSetter,
    feedback_status,
    noteToSend = ""
  ) => {
    try {
      console.log("⬅️⬅️⬅️⬅️ handleBulkFeedbackSubmission");
      let note = noteToSend || ticket_info.note;
      let feedback = feedback_status;
      let feedback_json = {
        id: ticket_info.id,
        feedback: feedback,
        approved_by: user.email,
        note: note,
      };
      let latestRows = newRows ? newRows : rows;
      let newRowsFeedback = latestRows.map((row) => {
        if (row.uuid === ticket_info.uuid) {
          return {
            ...row,
            feedback_status: feedback_status,
            note: note,
            approved_by: user.email,
            feedback_received_by_CE: false,
          };
        }
        return row;
      });
      updateRowsDB(newRowsFeedback);

      //Add ticket with feedback to list, in ordre to check if chrome extension receive feedback
      setlistTicketsWithFeedback([...listTicketsWithFeedback, ticket_info]);
      console.log(listTicketsWithFeedback);
      return await TicketsApi.updateFeedbackTicket(feedback_json);
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  };
  const handleUpdateFeedback = async (item) => {
    // console.log(item.id, "item is here in bulk feedback @");
    // row
    const userEmail = user.email;
    const updatedItem = { ...item, approved_by: userEmail };

    const result = await handleBulkFeedbackSubmission(
      updatedItem,
      rowsDB,
      setRowsDB,
      updatedItem.feedback || updatedItem.worker_action_status
    );
    if (result.data.status === "success") {
      rowsDBRef.current = rowsDBRef.current.map((row) =>
        row.id === updatedItem.id
          ? { ...row, feedback_status: updatedItem.feedback, feedback_received_by_CE: false }
          : row
      );
    }
  };
  const handleBulkApproval = async (feedback) => {
    currentFeedBackForBulkSelection.current = feedback;
    const userEmail = user.email;
    const approvalArr = selectedTickets.current.map((item) => ({
      ...item,
      id: item.id,
      feedback: feedback,
      note: "",
      approved_by: userEmail,
    }));
    try {
      setShouldClearSelection(false);
      setIsLoading(true);
      await Promise.all(approvalArr.map((item) => handleUpdateFeedback(item)));
      setIsLoading(false);
      currentFeedBackForBulkSelection.current = null;
      setShouldClearSelection(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  let loop_reps = 0;
  async function checkFeedbackStatus(allTickets) {
    console.log("checkFeedbackStatus");
    setresendingFeedback(true);
    console.log(allTickets);
    let startArray = allTickets;
    if (allTickets.length == 0) {
      console.log("no tickets with feedback in the list");
      setresendingFeedback(false);
    }
    for (let i = allTickets.length - 1; i >= 0; i--) {
      console.log("⭐inside loop resending feedback to ticket below");
      console.log(allTickets[i]);
      if (
        allTickets[i].feedback_received_by_CE === false &&
        allTickets[i].feedback_status !== "Q"
      ) {
        //if ticket feedback sent but extension did not receive it
        console.log(
          "🔔 ticket feedback sent but extension did not receive it, sending new notification..."
        );
        await TicketsApi.updateFeedbackTicket(allTickets[i]);
        loop_reps++;
      } else if (
        allTickets[i].feedback_received_by_CE === true &&
        allTickets[i].feedback_status !== "Q"
      ) {
        console.log("Removing ticket with feedback received");
        allTickets.splice(i, 1);
      }
      if (startArray.length != allTickets.length) {
        console.log("setlistTicketwithFeedback last IF");
        setlistTicketsWithFeedback(allTickets);
      }

      //after 7 seconds check if new list of tickets with feedback decreased to send a new notification
      setTimeout(() => {
        console.log("Inside loop check feedback");
        if (listTicketsWithFeedback.length > 0) {
          //CE received the notification and ticket was removed from array, no need to resend notification
          console.log(
            "CE received the notification and ticket was removed from array, no need to resend notification"
          );
          setresendingFeedback(false);
        } else {
          if (loop_reps > 10) {
            console.log("loop_reps > 10");
            loop_reps = 0;
            setresendingFeedback(false);
          } else {
            console.log("Feedback not yet received by CE... sending notification again");
            checkFeedbackStatus(listTicketsWithFeedback);
          }
        }
      }, 7000);
    }
  }
  //This is called when a new manager feedback is sent, to check if CE received feedback
  useEffect(() => {
    console.log("⭐⭐⭐⭐ listTicketsWithFeedback USEFFECT ");
    console.log(listTicketsWithFeedback);
    console.log("resendingFeedback", resendingFeedback);
    if (!resendingFeedback && listTicketsWithFeedback.length != 0) {
      //if checkFeedbackStatus is NOT already running
      checkFeedbackStatus(listTicketsWithFeedback);
    } else console.log("checkFeedbackStatus already running");
  }, [listTicketsWithFeedback]);

  useEffect(() => {
    if (rowsDB.length === 0) {
      selectedTickets.current = [];
    }
    console.log("next checking if tickets are still reserved");
    let allTickets = rowsDB.filter((row) => isTicketStillReserved(row));
    // console.log("allTickets", allTickets);
    // console.log("rowsDB", rowsDB);
    if (allTickets.length != rowsDB.length) {
      console.log("❌❌❌ allTickets != rowsDB");
      updateRowsDB(allTickets);
    }
  }, [rowsDB]);

  const handleSetRowsDB = (newItems) => {
    setRowsDB(newItems);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        rowsDB={rowsDB}
        setRowsDB={handleSetRowsDB}
        setlistTicketsWithFeedback={setlistTicketsWithFeedback}
        listTicketsWithFeedback={listTicketsWithFeedback}
      />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              sx={{
                gap: "8px",
                "@media (max-width: 767px)": {
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: "16px",
                },
              }}
            >
              <SuiTypography
                variant="h6"
                sx={{
                  "@media (max-width: 767px)": {
                    fontSize: "1.2rem",
                    alignSelf: "center",
                  },
                }}
              >
                Tickets in Queue
              </SuiTypography>
              {is_fetching_data ? <Spinner /> : null}

              <SuiBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  gap: "8px",
                  "@media (max-width: 767px)": {
                    flexDirection: "column",
                    width: "40%",
                    alignItems: "stretch",
                  },
                }}
              >
                <SuiBox
                  sx={{
                    "@media (max-width: 767px)": {
                      width: "100%",
                    },
                  }}
                >
                  <SuiButton
                    buttonColor="info"
                    variant={"outlined"}
                    onClick={() => handleBulkApproval("D")}
                    sx={{
                      width: { xs: "100%", sm: "160px" },
                      height: { xs: "36px", sm: "42px" },
                      fontSize: { xs: "0.875rem", sm: "1rem" },
                      mb: { xs: 1, sm: 0 },
                    }}
                    isLoading={isLoading && currentFeedBackForBulkSelection.current === "D"}
                    disabled={isBtnDisabled}
                  >
                    Bulk Rejection
                  </SuiButton>
                </SuiBox>
                <SuiBox
                  sx={{
                    "@media (max-width: 767px)": {
                      width: "100%",
                    },
                  }}
                >
                  <SuiButton
                    buttonColor="info"
                    variant={"gradient"}
                    onClick={() => handleBulkApproval("A")}
                    sx={{
                      width: { xs: "100%", sm: "150px" },
                      height: { xs: "36px", sm: "42px" },
                      fontSize: { xs: "0.875rem", sm: "1rem" },
                    }}
                    isLoading={isLoading && currentFeedBackForBulkSelection.current === "A"}
                    disabled={isBtnDisabled}
                  >
                    Bulk Approval
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>

            <SuiBox customClass={classes.tables_table}>
              <Table
                columns={columns}
                rowsDB={rowsDB}
                rowsWaitingToUpdate={newRows}
                setRowsDB={updateRowsDB}
                handleFeedbackSubmission={handleFeedbackSubmission}
                setRowsDB_instant={setRowsDB}
                handleCheckBoxCheck={handleCheckBoxCheck}
                shouldClearSelection={shouldClearSelection}
                selectedTickets={selectedTickets}
              />
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
