// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .ag-header-cell {
  flex: 1;
} */

.ag-cell-focus,
.ag-cell {
  border: none !important;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.ag-header-cell {
  background-color: white;
  color: rgb(131, 146, 171);
  font-size: 0.75rem;
  font-weight: 700;
}

.ag-header {
  border: none !important;
  text-transform: uppercase;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-root-wrapper {
  border: none !important;
}

.ag-body-vertical-scroll {
  display: none;
  width: 0;
}

.ag-cell.ag-cell-checkbox-input-wrapper {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/examples/Table/index.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;;EAEE,uBAAuB;EACvB,aAAa;EACb,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* .ag-header-cell {\n  flex: 1;\n} */\n\n.ag-cell-focus,\n.ag-cell {\n  border: none !important;\n  display: flex;\n  height: 100%;\n  width: 100%;\n  align-items: center;\n}\n\n.ag-header-cell {\n  background-color: white;\n  color: rgb(131, 146, 171);\n  font-size: 0.75rem;\n  font-weight: 700;\n}\n\n.ag-header {\n  border: none !important;\n  text-transform: uppercase;\n}\n\n.ag-header-cell-label {\n  justify-content: center;\n}\n\n.ag-root-wrapper {\n  border: none !important;\n}\n\n.ag-body-vertical-scroll {\n  display: none;\n  width: 0;\n}\n\n.ag-cell.ag-cell-checkbox-input-wrapper {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
