import { useState, useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import LocalActivitySharpIcon from "@mui/icons-material/LocalActivitySharp"; // Soft UI Dashboard React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/NotificationItem";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./notficationStyles.css";
// Custom styles for DashboardNavbar
import styles from "examples/Navbars/DashboardNavbar/styles";
import { ws, updateRowsDB } from "api/websocket";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";
//Import registerServiceWorker

// Images
import team2 from "assets/images/team-2.jpg";
var moment = require("moment");

function DashboardNavbar({
  absolute,
  light,
  isMini,
  rowsDB,
  setRowsDB,
  listTicketsWithFeedback,
  setlistTicketsWithFeedback,
}) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [allnotificationsui, setallnotificationsui] = useState(
    JSON.parse(localStorage.getItem("notifications"))
  );
  const classes = styles({ transparentNavbar, absolute, light, isMini });
  const route = useLocation().pathname.split("/").slice(1);
  // const notify = (notificationFormatted) => {
  //   console.log("Inside Notify()");
  //   toast(notificationFormatted);
  // };
  useEffect(() => {
    updateRowsDB(rowsDB);
  }, [rowsDB]);
  useEffect(() => {
    let websocket = new ws();
    console.log("inside useEffect()");
    websocket.init(
      setallnotificationsui,
      setRowsDB,
      listTicketsWithFeedback,
      setlistTicketsWithFeedback
    );
    return () => {
      websocket.close();
    };
  }, []);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      dispatch({
        type: "TRANSPARENT_NAVBAR",
        value: (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      });
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => dispatch({ type: "MINI_SIDENAV", value: !miniSidenav });
  // const handleConfiguratorOpen = () =>
  //   dispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  function RenderNotifications() {
    if (allnotificationsui && Array.isArray(allnotificationsui)) {
      console.log(allnotificationsui);
      return allnotificationsui
        .reverse()
        .map((singleNotification) => (
          <NotificationItem
            key={Math.random()}
            image={<LocalActivitySharpIcon color="primary" src={team2} alt="person" />}
            title={[singleNotification.header, "from " + singleNotification.data.worker_email]}
            date={moment(parseInt(singleNotification.data.reserved_at)).fromNow()}
            onClick={handleCloseMenu}
          />
        ));
    } else {
      return (
        <NotificationItem
          key={Math.random()}
          image={<LocalActivitySharpIcon color="primary" src={team2} alt="person" />}
          title={["No Notifications", "Yet"]}
          date="Now"
          onClick={handleCloseMenu}
        />
      );
    }
  }
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      style={{ marginTop: "1rem" }}
    >
      <RenderNotifications />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      className={classes.navbar}
    >
      <ToastContainer />
      <Toolbar className={classes.navbar_container}>
        <SuiBox customClass={classes.navbar_row} color="inherit" mb={{ xs: 1, md: 0 }}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </SuiBox>
        {isMini ? null : (
          <SuiBox customClass={classes.navbar_row}>
            <SuiBox pr={1}>
              <SuiInput
                placeholder="Type here..."
                withIcon={{ icon: "search", direction: "left" }}
                customClass={classes.navbar_input}
              />
            </SuiBox>
            <SuiBox
              color={light ? "white" : "inherit"}
              customClass={classes.navbar_section_desktop}
            >
              <IconButton
                size="small"
                color="inherit"
                className={classes.navbar_mobile_menu}
                onClick={handleMiniSidenav}
              >
                <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
              {/* <IconButton
                color="inherit"
                className={classes.navbar_icon_button}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton> */}
              <IconButton
                color="inherit"
                className={classes.navbar_icon_button}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon>notifications</Icon>
              </IconButton>
              {renderMenu()}
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  rowsDB: PropTypes.arrayOf(PropTypes.object),
  setRowsDB: PropTypes.func,
  listTicketsWithFeedback: PropTypes.arrayOf(PropTypes.object),
  setlistTicketsWithFeedback: PropTypes.func,
};

export default DashboardNavbar;
