import TicketsApi from "api/tickets";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// import StyledMenu from "components/MuiDropdown";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/TableAll";
import React, { useState, useEffect, useRef } from "react";
// Custom styles for the Tables
import styles from "layouts/tables/styles";
// import SuiInput from "components/SuiInput/index";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import { TimePicker } from "antd";
import Bulk_Action_Dropdown from "components/DropdownAllTicketsBulk";
import moment from "moment";

function Tables_All() {
  const classes = styles();
  let [rowsDB, setRowsDB] = useState([]);
  const [puller_name_list, setPuller_name_list] = useState([]);
  const [puller_tag_list, setPuller_tag_list] = useState([]);
  const [date, setDate] = useState(undefined);
  const [fromTime, setFromTime] = useState(undefined);
  const [toTime, setToTime] = useState(undefined);
  const [tableRef, settableRef] = useState(undefined);
  let gridApi = useRef(null);
  //add table useRef
  // const { columns } = confirmedTicketsTableData;
  //const { columns: prCols, rows: prRows } = projectsTableData;
  function formatDate(date) {
    let month_day = new Date(date).toLocaleDateString("en-us", { month: "short", day: "numeric" });
    let time = new Date(date).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return month_day + " - " + time;
  }

  const download_CSV = () => {
    console.log("Inside Download CSV");
    const csv_headers =
      "Worker Action, Reserved at, Event name, Event date, Ticket quantity, Total price, Seat row, Seat numbers, Seat section, Worker email, Tag, Category, Puller name, Feedback status, Approved by";

    var csv_rows = "";
    let ordered_rows = [
      "worker_action_status",
      "reserved_at",
      "event_name",
      "event_date",
      "ticket_quantity",
      "ticket_price",
      "seat_row",
      "seat_numbers",
      "seat_section",
      "worker_email",
      "tag_name",
      "category",
      "puller_name",
      "feedback_status",
      "approved_by",
    ];
    let ordered_columns_array = [];
    console.log("🎈tableRef");
    console.log(tableRef);
    const gridApi = tableRef;
    const rowCount = gridApi.getModel().getRowCount();

    let displayedRows = [];
    for (let i = 0; i < rowCount; i++) {
      displayedRows.push(gridApi.getDisplayedRowAtIndex(i).data);
    }
    displayedRows.forEach(function (row) {
      let ordered_row = [];
      for (let i = 0; i < ordered_rows.length; i++) {
        // console.log("🎈Next ordered_rows");
        // console.log(ordered_rows[i]);
        Object.keys(row).forEach((colName) => {
          // console.log(k);
          if (colName == ordered_rows[i]) {
            // console.log("😁 Matched");
            let colValue = row[colName];
            if (colName == "reserved_at") ordered_row.push(formatDate(parseInt(colValue)));
            else if (colName == "event_date") ordered_row.push(colValue.replaceAll(" • ", " - "));
            else if (colName == "worker_action_status") ordered_row.push("Purchased");
            else {
              // console.log(colValue);
              ordered_row.push(colValue);
            }
          }
        });
      }
      ordered_columns_array.push(ordered_row);
    });
    // console.log(ordered_columns_array);
    for (let i = 0; i < ordered_columns_array.length; i++) {
      let stringed = ordered_columns_array[i].join("__").replace(/,/gi, "'");
      // console.log("Stringed:");
      // console.log(stringed);
      let clean_string = stringed.replace(/__/gi, ",");
      csv_rows += clean_string;
      csv_rows += "\n";
    }

    let csv_Complete = csv_headers + "\n" + csv_rows;
    // console.log(csv_Complete);
    //let encodedUri = encodeURI(csv_Complete);

    let a = document.createElement("a");
    a.setAttribute("style", "display:none;");
    document.body.appendChild(a);
    let blob = new Blob([csv_Complete], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = "Ticket Relay - All Tickets.csv";
    a.click();
  };
  useEffect(async () => {
    const today = moment();
    // Get yesterday's date with the same time as today
    const yesterday = moment().subtract(1, "days").set({
      hour: today.hours(),
      minute: today.minutes(),
      second: today.seconds(),
    });
    // Format dates for API request
    const from = yesterday.format("YYYY-MM-DD");
    const to = today.format("YYYY-MM-DD");
    console.log("🎈from", from);
    console.log("🎈to", to);
    TicketsApi.getFilteredAllTickets(from, to).then(async (allTickets) => {
      console.log("🎈allTickets", allTickets);
      let sortTickets = () =>
        allTickets.data.sort(function (x, y) {
          return parseInt(y.reserved_at) - parseInt(x.reserved_at);
        });
      setRowsDB(allTickets.data.status != "error" ? sortTickets() : []);
    });
    const puller_list = await TicketsApi.get_puller_list();
    const tag_list = await TicketsApi.get_tag_list();
    setPuller_tag_list([...tag_list.tag_names]);
    setPuller_name_list([...puller_list.puller_names]);
  }, []);

  // date range filter

  const disabledDate = (date) => {
    const currentDate = new Date();
    return date > currentDate;
  };

  const callApi = async () => {
    if (date !== null && date !== undefined) {
      console.log("⭐callApi", date);
      let from = moment(date[0]).format("YYYY-MM-DD");
      let to = moment(date[1]).format("YYYY-MM-DD");
      if (fromTime && toTime) {
        console.log("Time exists", fromTime);
        from = moment(date[0]);
        to = moment(date[1]);
        from = from
          .set({
            hour: fromTime.$H,
            minute: fromTime.$m,
            second: 0,
          })
          .format("YYYY-MM-DD HH:mm:ss");
        to = to
          .set({
            hour: toTime.$H,
            minute: toTime.$m,
            second: 0,
          })
          .format("YYYY-MM-DD HH:mm:ss");
      }
      TicketsApi.getFilteredAllTickets(from, to).then(async (allTickets) => {
        console.log("⭐callApi", allTickets);
        setRowsDB(allTickets.data.status != "error" ? allTickets.data : []);
      });
    } else if (date !== undefined) {
      TicketsApi.getAllTickets_ViewSet(1).then(async (allTickets) => {
        let sortTickets = () =>
          allTickets.data.sort(function (x, y) {
            return parseInt(y.reserved_at) - parseInt(x.reserved_at);
          });
        setRowsDB(allTickets.data.status != "error" ? sortTickets() : []);
      });
    }
  };
  const handleFromTimeChange = (time) => {
    console.log("handleFromTimeChange", time);
    setFromTime(time);
  };
  const handleToTimeChange = (time) => {
    console.log("handleToTimeChange", time);
    setToTime(time);
  };
  useEffect(() => {
    callApi();
  }, [fromTime, toTime, date]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">All Tickets</SuiTypography>

              <SuiBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                textAlign="center"
                p={3}
              >
                <SuiBox pr={1}>
                  <Bulk_Action_Dropdown
                    rowsDB={rowsDB}
                    setRowsDB={setRowsDB}
                    gridRef={tableRef}
                    gridApi={gridApi}
                    puller_name_list={puller_name_list}
                    tag_list={puller_tag_list}
                  />
                </SuiBox>
                <SuiBox pr={1}>
                  <DateRangePicker
                    value={date}
                    id="dateRangePicker"
                    onChange={setDate}
                    size="md"
                    placeholder="Select Date Range"
                    style={{ width: 260, display: "block", margin: "auto" }}
                    placement="bottomEnd"
                    disabledDate={disabledDate}
                  />
                  <SuiBox display="flex" alignItems="center" marginTop="5px">
                    <TimePicker
                      onChange={handleFromTimeChange}
                      format="HH:mm"
                      use12Hours
                      placeholder="From Time"
                    />
                    <span style={{ margin: "0 10px" }}>to</span>
                    <TimePicker
                      onChange={handleToTimeChange}
                      format="HH:mm"
                      use12Hours
                      placeholder="To Time"
                    />
                  </SuiBox>
                </SuiBox>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  style={{ marginRight: "5px" }}
                  disableElevation
                  onClick={download_CSV}
                  endIcon={<DownloadIcon />}
                >
                  Download CSV
                </Button>
                {/* <StyledMenu setRowsDB={setRowsDB} table="confirmed" /> */}
              </SuiBox>
            </SuiBox>
            <SuiBox customClass={classes.tables_table}>
              <Table
                rowsDB={rowsDB}
                setRowsDB={setRowsDB}
                settableRef={settableRef}
                puller_name_list={puller_name_list}
                puller_tag_list={puller_tag_list}
                gridApi={gridApi}
              />
            </SuiBox>
          </Card>
        </SuiBox>
        {/* <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SuiTypography variant="h6">Tickets with Feedback</SuiTypography>
          </SuiBox>
          <SuiBox customClass={classes.tables_table}>
            <Table columns={prCols} rows={prRows} />
          </SuiBox>
        </Card> */}
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables_All;
