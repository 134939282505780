// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tables_table .MuiTableRow-root:not(:last-child) td {
  border-bottom: 0.0625rem solid #dee2e6;
}
.ag-center-cols-viewport {
overflow-x: visible;
}

`, "",{"version":3,"sources":["webpack://./src/layouts/tables/style.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;AACA;AACA,mBAAmB;AACnB","sourcesContent":[".tables_table .MuiTableRow-root:not(:last-child) td {\n  border-bottom: 0.0625rem solid #dee2e6;\n}\n.ag-center-cols-viewport {\noverflow-x: visible;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
