import axios from "./index";
let base = "users";
class TicketsApi {
  static getQueuedTickets = () => {
    return axios.get(`${base}/getQueuedTickets`);
  };

  static getConfirmedTickets = (daterange) => {
    return axios.get(`${base}/getConfirmedTickets?daterange=${daterange}`);
  };

  static getAllTickets_ViewSet = (daterange) => {
    return axios.get(`${base}/GetAllTickets_ViewSet?daterange=${daterange}`);
  };
  // GetAllTickets-filtered
  static getFilteredAllTickets = (from, to) => {
    return axios.get(`${base}/GetAllTickets-ByDateTime?from_date=${from}&to_date=${to}`);
  };
  static getFilteredConfirmedTickets = (from, to) => {
    return axios.get(`${base}/getConfirmedTicketsByActualDate?from_date=${from}&to_date=${to}`);
  };

  static getExpiredTickets = (daterange) => {
    return axios.get(`${base}/getExpiredTickets?daterange=${daterange}`);
  };

  static getFilteredExpiredTickets = (from, to) => {
    return axios.get(`${base}/getExpiredTicketsByActualDate?from_date=${from}&to_date=${to}`);
  };

  static getDashboardData = () => {
    return axios.get(`${base}/getDashboardInfo`);
  };

  static get_puller_list = async () => {
    const response = await axios.get(`${base}/get-pullerlist`);
    return response.data; // Return the response data
  };
  static get_tag_list = async () => {
    const response = await axios.get(`${base}/get-tag-list`);
    return response.data; // Return the response data
  };

  static Register = (data) => {
    return axios.post(`${base}/register`, data);
  };

  static updateFeedbackTicket = (data) => {
    return axios.post(`${base}/addManagerFeedback`, data);
  };
  static update_ticket = async (data) => {
    const response = await axios.post(`${base}/update_Ticket_Field`, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json", // Set the Content-Type header
        Accept: "application/json", // Set the Content-Type header
      },
    });
    return response.data; // Return the response data
  };

  static timerTimeOut = (data) => {
    axios.post(`${base}/timerTimeOut`, data).then((result) => {
      return result;
    });
  };
  static managerForceWorkerAction = (data) => {
    axios.post(`${base}/managerForceWorkerAction`, data).then((result) => {
      return result;
    });
  };
}

export default TicketsApi;
