import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import SuiButton from "components/SuiButton";
import "./styles.css";
import { IconButton } from "@mui/material";

const FormDialog = forwardRef(
  (
    {
      btn_text,

      children,
      submitFunction,
      cancelBtnText,
      submitBtnText,
      dialogtitle,
      submitBtnHeight,
      submitBtnWidth,
    },
    ref
  ) => {
    ref;
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    useEffect(() => {
      return () => {
        setIsLoading(false); // cleanup sets isMounted to false
        setOpen(false); // cleanup sets isMounted to false
      };
    }, []);
    function timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    //add logs
    const handleClickOpen = React.useCallback((e) => {
      console.log("handleClickOpen");
      e.stopPropagation();
      setOpen(true);
      setTimeout(() => {}, 100);
      //rowNode?.node?.setSelected(false);
    }, []);

    const handleClose = (e) => {
      console.log("handleClose");
      e.stopPropagation();
      setOpen(false);
    };

    const handleSubmit = async (e) => {
      console.log("handleSubmit");
      setIsLoading(true);
      e.stopPropagation();
      await timeout(50);
      submitFunction();
      setOpen(false);

      setIsLoading();
    };

    const handleBackdropClick = (event) => {
      event.stopPropagation();
    };

    return (
      <div>
        {dialogtitle !== "Delete" && dialogtitle !== "Purchase" ? (
          <SuiButton variant={"gradient"} buttonColor={"primary"} onClick={handleClickOpen}>
            {btn_text.content}
          </SuiButton>
        ) : dialogtitle === "Delete" ? (
          <IconButton color="error" onClick={handleClickOpen}>
            {btn_text.content}
          </IconButton>
        ) : (
          <IconButton color="success" onClick={handleClickOpen}>
            {btn_text.content}
          </IconButton>
        )}

        <Dialog open={open} onClose={handleClose} id="modal" onBackdropClick={handleBackdropClick}>
          <DialogTitle>{dialogtitle}</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <SuiButton
              variant={"gradient"}
              buttonColor={"secondary"}
              onClick={handleClose}
              disabled={isLoading}
            >
              {cancelBtnText}
            </SuiButton>
            <SuiButton
              variant={"gradient"}
              buttonColor={"primary"}
              onClick={handleSubmit}
              isLoading={isLoading}
              height={submitBtnHeight}
              width={submitBtnWidth}
            >
              {submitBtnText}
            </SuiButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);
FormDialog.displayName = "FormDialog";
FormDialog.propTypes = {
  btn_text: PropTypes.object,
  ticket_info: PropTypes.object,
  setRowsDB: PropTypes.func,

  rows: PropTypes.array,
  submitFunction: PropTypes.func,
  cancelBtnText: PropTypes.string,
  submitBtnText: PropTypes.string,
  dialogtitle: PropTypes.string,
  children: PropTypes.node,
  submitBtnHeight: PropTypes.string,
  submitBtnWidth: PropTypes.string,
};

export default FormDialog;
