// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import TicketsApi from "api/tickets";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import moment from "moment";
// Soft UI Dashboard React base styles

// Data
import { useState, useEffect } from "react";

function Dashboard() {
  let total_amount_per_day = {
    seven_Days_Ago: 0,
    six_Days_Ago: 0,
    five_Days_Ago: 0,
    four_Days_Ago: 0,
    three_Days_Ago: 0,
    two_Days_Ago: 0,
    Yesterday: 0,
    today: 0,
  };
  let LS_total_amount_per_day = {
    seven_Days_Ago: 0,
    six_Days_Ago: 0,
    five_Days_Ago: 0,
    four_Days_Ago: 0,
    three_Days_Ago: 0,
    two_Days_Ago: 0,
    Yesterday: 0,
    today: 0,
  };
  let LS_purchase_grap_data = localStorage.getItem("purchase_graph_data");
  if (LS_purchase_grap_data) LS_total_amount_per_day = JSON.parse(LS_purchase_grap_data);
  console.log("LS_total_amount_per_day", LS_total_amount_per_day);
  let [dashboardData, setdashboardData] = useState([]);
  let [purchase_graph_data, setpurchase_graph_data] = useState(LS_total_amount_per_day);
  let populate_tickets_purchased_graph = async (tickets_purchased_data) => {
    console.log("populate_tickets_purchased_graph()");
    var REFERENCE = moment(); // fixed just for testing, use moment();
    var TODAY = REFERENCE.clone().startOf("day");
    var YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
    var TWO_DAY_AGO = REFERENCE.clone().subtract(2, "days").startOf("day");
    var THREE_DAY_AGO = REFERENCE.clone().subtract(3, "days").startOf("day");
    var FOUR_DAY_AGO = REFERENCE.clone().subtract(4, "days").startOf("day");
    var FIVE_DAY_AGO = REFERENCE.clone().subtract(5, "days").startOf("day");
    var SEVEN_DAY_AGO = REFERENCE.clone().subtract(6, "days").startOf("day");
    function parseFloat_ticket_price(ticket_price) {
      //add try catch and if error return 0
      try {
        let noDecimal = ticket_price.includes(".")
          ? ticket_price.match(/.*(?=\.)/)[0]
          : ticket_price;
        //parse to float by removing $ signs
        var regex = /[+-]?\d+(\.\d+)?/g;
        var float_value = parseFloat(noDecimal.match(regex)[0]);
        return float_value;
      } catch {
        return 0;
      }
    }
    function sum_all_tickets_in_right_column(ticketDate, float_ticket_price, resolve) {
      if (moment(ticketDate).isSame(TODAY, "d")) {
        total_amount_per_day.today += float_ticket_price;
      } else if (moment(ticketDate).isSame(YESTERDAY, "d")) {
        total_amount_per_day.Yesterday += float_ticket_price;
      } else if (moment(ticketDate).isSame(TWO_DAY_AGO, "d")) {
        total_amount_per_day.two_Days_Ago += float_ticket_price;
      } else if (moment(ticketDate).isSame(THREE_DAY_AGO, "d")) {
        total_amount_per_day.three_Days_Ago += float_ticket_price;
      } else if (moment(ticketDate).isSame(FOUR_DAY_AGO, "d")) {
        total_amount_per_day.four_Days_Ago += float_ticket_price;
      } else if (moment(ticketDate).isSame(FIVE_DAY_AGO, "d")) {
        total_amount_per_day.five_Days_Ago += float_ticket_price;
      } else if (moment(ticketDate).isSame(SEVEN_DAY_AGO, "d")) {
        total_amount_per_day.seven_Days_Ago += float_ticket_price;
      }
      resolve();
    }
    async function fit_ticket_in_graph_data(tickets_purchased_data) {
      return new Promise((resolve) => {
        let ticketDate = parseInt(tickets_purchased_data.reserved_at);
        var float_ticket_price = parseFloat_ticket_price(tickets_purchased_data.ticket_price);
        //put every ticket in right date
        sum_all_tickets_in_right_column(ticketDate, float_ticket_price, resolve);
      });
    }
    for (let i = 0; i < tickets_purchased_data.length; i++) {
      await fit_ticket_in_graph_data(tickets_purchased_data[i]);
    }
    setpurchase_graph_data(total_amount_per_day);
    localStorage.setItem("purchase_graph_data", JSON.stringify(total_amount_per_day));
    console.log(total_amount_per_day);
  };
  useEffect(() => {
    let isMounted = true;
    TicketsApi.getDashboardData().then((response) => {
      if (isMounted) {
        setdashboardData(response.data.data);
        populate_tickets_purchased_graph(response.data.tickets_confirmed_7_days);
      }
      return () => {
        isMounted = false;
      };
    });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "today's queued tickets" }}
                count={dashboardData.today_tickets_in_Queue}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "today's purchased tickets" }}
                count={dashboardData.today_tickets_purchased}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "7 Days Purchased Tickets" }}
                count={dashboardData.seven_days_tickets_purchased}
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "30 Days Purchased Tickets" }}
                count={dashboardData.thirty_days_tickets_purchased}
                icon={{
                  color: "info",
                  component: "shopping_cart",
                }}
              />
            </Grid>
          </Grid>
          <Grid marginTop={"15px"}>
            <GradientLineChart
              title="Total Purchase Amount By Day"
              chart={{
                labels: [
                  "6 Days Ago",
                  "5 Days Ago",
                  "4 Days Ago",
                  "3 Days Ago",
                  "2 Days Ago",
                  "Yesterday",
                  "Today",
                ],
                datasets: [
                  {
                    label: "Current Week",
                    color: "info",
                    data: [
                      purchase_graph_data.seven_Days_Ago,
                      purchase_graph_data.five_Days_Ago,
                      purchase_graph_data.four_Days_Ago,
                      purchase_graph_data.three_Days_Ago,
                      purchase_graph_data.two_Days_Ago,
                      purchase_graph_data.Yesterday,
                      purchase_graph_data.today,
                    ],
                  },
                ],
              }}
            />
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
